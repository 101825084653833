.modal {
    /* Default styles */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 500px;
    overflow: auto;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;
    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 480px) {
        width: 60%;
    }
}
.thankyou {
    width: 700px;
    padding: 0;
    outline: none;
    @media screen and (max-width: 786px) {
        width: 90%;
    }
    // top: 40%;
}
.thankyou-header {
    outline: none;
    background-image: url("../../../../Assets/thankyou_bg.svg");
    background-position: center;
    background-size: cover;
    height: 250px;
    display: grid;
    justify-items: center;
    align-items: center;
    align-content: baseline;
    padding: 2rem;
    gap: 0.5rem;
    position: relative;
    button {
        position: absolute;
        right: 10px;
        top: 10px;
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 1.5rem;
        cursor: pointer;
    }
    img {
        max-width: 150px;
        width: 90%;
    }
    h3 {
        color: #f71735;
        font-size: 30px;
    }
    p {
        font-weight: 550;
        font-size: 20px;
        text-align: center;
    }
}
.body {
    background: #fffaf4;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 1rem;
    gap: 2rem;
    h3 {
        font-size: 20px;
        text-align: center;
    }

    .Links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        a {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #d34fba;
            border: 1px solid #d34fba;
            padding: 0.5rem;
            font-size: 1rem;
            &:hover {
                color: white;
                background-color: #d34fba;
            }
        }
    }
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.form-wrapper {
    display: grid;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
    & > button {
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 1.5rem;
        border: none;
        background-color: inherit;
        cursor: pointer;
    }
    h2 {
        // margin-bottom: 1rem;
        text-align: center;
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #ccc;
    }
    form {
        display: grid;
        gap: 1rem;

        .form-control {
            position: relative;
            display: grid;
            grid-template-columns: 0.5fr 1fr;
            align-items: center;
            gap: 1rem;
            width: 100%;
            label {
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
                font-size: 1.1rem;
            }

            textarea {
                border: 1px solid #ccc;

                outline: none;
                resize: none;
                font-size: 1rem;
                height: 5rem;
                width: 100%;
                padding: 0.5rem;
                width: 90%;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
                text-indent: 0.4rem;
            }
            .form-control-one input {
                height: 1rem;
                border: 1px solid #ccc;
                font-size: 1.1rem;
                color: black;
                height: 2rem;
                width: 100%;
                max-width: 15rem;
                text-indent: 1rem;
                outline: none;
            }
            .error {
                color: red;
            }
            .radio-buttons {
                display: flex;
                align-items: center;
                gap: 1rem;
                label {
                    cursor: pointer;
                    text-transform: uppercase;
                    display: flex;
                    gap: 0.5rem;
                }
            }
        }
    }
}
.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-self: center;
}
.submit {
    background-color: #1e87db;
    color: white;
    text-align: center;
    border-radius: 10px;
    border: 1px solid;
    cursor: pointer;
    padding: 0 1rem;
    height: 2.5rem;
}
.close {
    width: fit-content;
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 0 1rem;
    height: 2.5rem;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@media screen and (max-width: 700px) {
    .form-control {
        display: grid;
        grid-template-columns: 1fr !important;
        gap: 0.5rem !important;
    }
    .modal {
        width: 60%;
    }
}
@media screen and (max-width: 500px) {
    .form-wrapper {
        form {
            input,
            select {
                width: 100% !important;
            }
        }
    }
}
@media screen and (max-width: 786px) {
    .thankyou-header {
        height: unset;
        // padding: 0.5rem;
        justify-items: center;
        img {
            width: 100px;
        }
        h3 {
            font-size: large;
        }
        p {
            font-size: small;
        }
    }
    .body {
        h3 {
            font-size: large;
        }
        .Links a {
            font-size: small;
        }
    }
}
